import React from "react";
import AppBreadcrumb from "components/layout-components/AppBreadcrumb";

export const PageHeader = ({ title, display }) => {
  return display ? (
    <div className="app-page-header">
      <h3 className="mb-2 mr-3 font-weight-semibold">{title ? title : "Home"}</h3>
    </div>
  ) : null;
};

export default PageHeader;
